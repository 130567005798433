<!--
 * @Description: 门店
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:34:02
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-06-02 15:51:42
-->


<template>
  <!-- <el-tabs v-model="tabActiveName">
  <el-tab-pane label="全部门店" name="shop">-->
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit" round icon="plus" v-if="is_can_edit">新增门店</el-button>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="图片" width="120">
        <template #default="scope">
          <el-image
            style="width: 80px; height: 60px"
            :src="scope.row.pic_url"
            :preview-src-list="[scope.row.pic_url]"
            :initial-index="0"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name" width="200"></el-table-column>
      <!-- <el-table-column label="管理者总数" prop="emp_count" width="100"></el-table-column> -->
      <el-table-column label="状态" width="120">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="店长" width="220">
        <template #default="scope">
          <div style="display: flex; align-items: center" v-if="scope.row.master">
            <!-- <el-avatar :src="scope.row.master.avatar_pic" size="small"></el-avatar> -->
            <span style="margin-left: 10px">
              {{
              scope.row.master.chs_name
              }}
            </span>
            <span>({{ scope.row.master.mobile_phone }})</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="管家" min-width="300">
        <template #default="scope">
          <div v-if="scope.row.emp_list">
            {{
            scope.row.emp_list
            .map((x) => `${x.chs_name}(${x.mobile_phone})`)
            .join("，")
            }}
            <!-- <span v-for="item in scope.row.emp_list" :key="item.id">
                  <span style="margin-left: 10px">{{ item.chs_name }}</span>
                  <span>({{ item.mobile_phone }})、</span>
            </span>-->
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="管理员" width="800">
            <template #default="scope">
              <div class="row" v-if="scope.row.manager_list">
                <div class="col" v-for="(item, index) in scope.row.manager_list" :key="index">
                  <div class="box">
                    <b>{{ item.post_name }}</b>
                    <div>
                      <el-link v-for="emp in item.emp_list" :key="emp">{{ emp.chs_name }}{{ emp.mobile_phone }}</el-link>
                    </div>
                  </div>
                  <div class="space" v-if="index < scope.row.manager_list.length - 1">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </template>
      </el-table-column>-->

      <!-- <el-table-column label="描述" prop="description"></el-table-column> -->
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="220">
        <template #header>
          <el-button
            size="small"
            round
            icon="Tickets"
            @click="openDraggable()"
            v-if="is_can_sort"
          >排序</el-button>
        </template>
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            icon="Edit"
            @click="onEdit(scope.row)"
            v-if="is_can_edit"
          >修改</el-button>
          <!-- <el-button type="text" size="small" @click="showEmp(scope.row)"
            >门店人员</el-button
          >-->
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)" v-if="is_can_del">
            <template #reference>
              <el-button link type="danger" size="small" icon="Delete">删除</el-button>
            </template>
          </el-popconfirm>
          <el-button
            type="primary"
            icon="plus"
            link
            size="small"
            @click="onOpenSetManager(scope.row)"
            v-if="is_can_mgr"
          >设置管家</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <!-- </el-tab-pane>
    <el-tab-pane label="门店管理岗位" name>
      <position></position>
    </el-tab-pane>
  </el-tabs>-->

  <edit-mendian :item="current" :reload="loadData"></edit-mendian>
  <!-- <mendian-emp :item="open_item" :reload="(open_item = null)"></mendian-emp> -->

  <el-dialog :title="manager_title" v-model="visibleMasterDialog" width="800px">
    <div v-if="current_shop.emp_list" style="height: 260px">
      <el-tag
        v-for="(item, i) in current_shop.emp_list"
        :key="item.id"
        style="margin: 0 10px 10px 0"
        closable
        @close="current_shop.emp_list.splice(i, 1)"
      >{{ item.chs_name }}、{{ item.mobile_phone }}</el-tag>
    </div>
    <template #footer>
      <div class="flex-row flex-between">
        <emp-choose @success="managerChangeSuccess"></emp-choose>
        <el-button type="primary" @click="onManagerSubmit" :loading="saving">确定</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog
    title="排序(拖拽排序)"
    v-model="visibleSortDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <draggable item-key="id" v-model="sort_list">
        <template #item="{ element, index }">
          <div class="draggable_row">{{ index + 1 }}、({{ element.name }})</div>
        </template>
      </draggable>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSortSubmit" :loading="sort_saving">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import EditMendian from "./edit_mendian.vue";
// import MendianEmp from "./mendian_emp.vue";
// import Position from "./mendian_position.vue";
import EmpChoose from "@/views/components/emp_choose.vue";
import Draggable from "vuedraggable";

export default {
  components: {
    EditMendian,
    // MendianEmp,
    Draggable,
    EmpChoose,
  },
  data() {
    return {
      loading: false,
      saving: false,
      sort_saving: false,
      visibleMasterDialog: false,
      visibleSortDialog: false,
      tableData: [],
      current: null,
      open_item: null,
      //tab切换名称
      tabActiveName: "shop",
      current_shop: null,
      manager_title: "",
      //排序的列表
      sort_list: [],
      is_can_edit: false,
      is_can_del: false,
      is_can_mgr: false,
    };
  },
  created() {
    this.loadData();

    this.is_can_edit = this.$power("seller/v1/shop/edit");
    this.is_can_del = this.$power("seller/v1/shop/del");
    this.is_can_mgr = this.$power("seller/v1/shop/emp/batch");
    this.is_can_sort = this.$power("seller/v1/shop/sort");
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;

      this.$http.post("seller/v1/shop").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/shop/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * 查看门店人员
     */
    showEmp(item) {
      this.open_item = item;
    },

    /**
     * 设置管家弹框
     */
    onOpenSetManager(item) {
      this.visibleMasterDialog = true;
      this.current_shop = JSON.parse(JSON.stringify(item));
      this.manager_title = `设置【${item.name}】管家`;
      if (!this.current_shop.emp_list) {
        this.current_shop.emp_list = [];
      }
    },

    /**
     * 选择店长回调
     */
    managerChangeSuccess(list) {
      // this.current_shop.emp_list = list;
      list.forEach((item) => {
        if (!this.current_shop.emp_list.find((o) => o.id == item.id)) {
          this.current_shop.emp_list.push(item);
        }
      });
    },
    /**
     * 设置门店店长
     */
    onManagerSubmit() {
      if (this.current_shop && this.current_shop.emp_list) {
        this.saving = true;
        this.$http
          .post("seller/v1/shop/emp/batch", {
            shop_id: this.current_shop.id,
            emp_ids: this.current_shop.emp_list.map((x) => x.id),
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.visibleMasterDialog = false;
              this.loadData();
            }
          })
          .finally(() => (this.saving = false));
      } else {
        this.$message.warning("请选择人员");
      }
    },

    /**
     * 排序
     */
    openDraggable() {
      this.sort_list = [];
      this.tableData.forEach((x) => {
        this.sort_list.push({
          id: x.id,
          name: x.name,
        });
      });
      this.visibleSortDialog = true;
    },

    /**
     * 提交排序
     */
    onSortSubmit() {
      this.$http.post('seller/v1/shop/sort',this.sort_list.map(x=>x.id)).then(res=>{
        if(res.code==0){
          this.$message.success(res.msg);
          this.visibleSortDialog=false;
          this.loadData();
        }
      });
    },
  },
};
</script>

<style  scoped>
.row {
  display: flex;
  align-items: center;
}

.row .col {
  flex-grow: 1;
  position: relative;
  display: flex;
}

.row .col:last-child {
  margin-right: 0px;
}

.row .col .space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.row .col .arrow {
  position: relative;
  width: 10px;
  height: 10px;
  border: 2px solid var(--el-text-color-placeholder);
  border-left: 0;
  border-top: 0;
  transform: rotate(-45deg) translateY(-4px);
}

.row .col .box {
  flex: 1;
}

.emp_item {
  display: flex;
  align-items: center;
}

/* 排序 */
.draggable_row {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
  cursor: pointer;
}

</style>